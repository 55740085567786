<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <button
            v-if="$can('export_user_reseller')"
            class="btn btn-success"
            type="button"
            @click="exportTable"
          >
            Export
          </button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Name, Email, Referral Code"
          >
            <b-input-group>
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Name, Email, Referral Code ..."
                @keypress="doFilter"
                @keyup.delete="doFilter"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  variant="secondary"
                  @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group class="mb-3" label="Date :" label-for="daterange">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker
                id="daterange"
                :start="startDate"
                :end="endDate"
                @picker="doDateFilter"
              ></range-picker>
              <b-input-group-append>
                <b-button
                  type="button"
                  variant="secondary"
                  @click="resetDateFilter"
                  >Reset</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm="12">
        <b-form-group
            class="mb-3"
            label="Search By Organization :"
            label-for="so"
          >
            <b-form-select id="so" v-model="organization" :options="organizationOptions" @change="doFilterOrganization"></b-form-select>
          </b-form-group>
      </b-col>
    </b-row>
    <vuetable
      ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="name-slot" slot-scope="prop">
        <span>{{ prop.rowData.name }} <br> ({{ prop.rowData.email }})</span>
      </template>
      <template slot="organization-slot" slot-scope="prop">
        <span>{{ prop.rowData.organization === null ? '-' : prop.rowData.organization }}</span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div class="custom-actions">
          <button
            class="btn btn-info"
            v-if="$can('detail_user_reseller')"
            @click="onAction('detail-item', prop.rowData, prop.rowIndex)"
          >
            Detail
          </button>
        </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

      <vuetable-pagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import accounting from "accounting";
import Vue from "vue";
import axios from 'axios'
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import moment from "moment/src/moment";
let startDate = "";
let endDate = "";
Vue.use(Vuetable);

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  prop: {
    rowData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      isLoading: false,
      filterText: "",
      organizationOptions: [],
      organization: '',
      timeNow: moment().format("YYYY-MM-DD hh:mm:ss"),
      errors: {
        code: "",
        message: "",
        status: "",
      },
      apiUrl:
        process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `user-reseller`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      },
      sortOrder: [
        {
          field: "created_at",
          sortField: "voucher_reseller_user.created_at",
          direction: "desc",
        },
      ],
      moreParams: {},
      fields: [
        {
          name: "created_at",
          sortField: "voucher_reseller_user.created_at",
          title: "Register Date",
          titleClass: "center aligned",
        },
        {
          name: "name-slot",
          sortField: "name",
          title: "User",
          titleClass: "center aligned",
        },
        {
          name: "organization-slot",
          title: "Organization",
          titleClass: "center aligned",
        },
        {
          name: "reseller_id",
          title: "Reseller ID",
          titleClass: "center aligned",
        },
        {
          name: "bought",
          title: "Voucher Bought",
          dataClass: "right aligned",
          titleClass: "center aligned",
        },
        {
          name: "redeemed",
          title: "Voucher Redeemed",
          titleClass: "center aligned",
          dataClass: "right aligned",
        },
        {
          name: "actions-slot",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned",
        },
      ],
    };
  },
  created() {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
    axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/organization-command?reseller=true').then((response) => {
      let data = response.data.data

      let attr = [
        { text: 'Pilih Organisasi', value: '' }
      ]
        data.map((v) => {
          return attr.push({ text: v.text, value: v.value })
        })
      this.organizationOptions = attr
    })
  },
  methods: {
    doFilterOrganization () {
      this.partner = ''
      this.$events.$emit('organization-set', this.organization)
    },
    onOrganizationFilterSet (organization) {
      this.moreParams = {
        'organization' : organization === '' ? null : organization
      }
      Vue.nextTick( () => this.$refs.vuetable.refresh())
    },
    exportTable() {
      this.isLoading = true;
      if (this.startDate != "" && this.endDate != "") {
        startDate = this.startDate;
        endDate = this.endDate;
      } else {
        endDate = this.$setDate.format("YYYY-MM-DD");
        startDate = this.$setDate.subtract(6, "days").format("YYYY-MM-DD");
      }

      this.$http
        .get(`user-reseller-export/` + startDate + `/` + endDate)
        .then((result) => {
          this.isLoading = false;
          const exportPath = result.data.meta.data;
          window.location.href = exportPath;
        })
        .catch((error) => {
          if (error.response) {
            this.isLoading = false;
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        });
    },
    doFilter() {
      this.$events.fire("filter-set", this.filterText);
    },
    resetFilter() {
      this.filterText = ""; // clear the text in text input
      this.$events.fire("filter-reset");
    },
    weight(value) {
      return value + " Gram";
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    resetDateFilter() {
      this.startDate = "";
      this.endDate = "";
      this.$events.$emit("date-filter-reset");
    },
    formatNumber(value) {
      return "Rp." + accounting.formatNumber(value, 2);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onAction(action, data) {
      if (action == "detail-item") {
        this.$router.push({
          name: "Detail User Reseller",
          params: { id: data.id },
        });
      }
    },
    onFilterSet(filterText) {
      this.moreParams = {
        filter: filterText,
      };
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    doDateFilter(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.$events.fire("date-set", [this.startDate, this.endDate]);
    },
    onDateSet() {
      this.start_from = this.startDate;
      this.end_to = this.endDate;
      this.moreParams = {
        start_from: this.start_from,
        end_to: this.end_to,
      };
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onDateFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
  },
  mounted() {
    this.$events.$on("filter-set", (eventData) => this.onFilterSet(eventData));
    this.$events.$on("date-set", (eventData) => this.onDateSet(eventData));
    this.$events.$on("filter-reset", () => this.onFilterReset());
    this.$events.$on("date-filter-reset", () => this.onDateFilterReset());
    this.$events.$on('organization-set', eventData => this.onOrganizationFilterSet(eventData))
  },
};
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
